import React from "react";
import why305 from "images/why305.png";
import Badge from "shared/Badge/Badge";

const WhyLevel305 = () => {
  return (
    <div className=" text-[#E2E8F0] py-16 px-8 md:px-20">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-10 items-center">
        {/* Left Column: Text Section */}
        <div className="space-y-8">
          <h2 className="text-4xl font-bold ">Why Infinney</h2>

          {/* Feature 1: Diverse Fleet of Vehicles */}
          <Badge name="Fleet" />
          <div className="flex items-start space-x-4">
            <div>
              <span className="text-xl font-bold text-[#E2E8F0]">
                Diverse Fleet of Vehicles
              </span>
              <p className="text-[#94A3B8] max-w-[80%]">
                We also offer luxury vehicles for those special occasions when
                you want to travel in style.
              </p>
            </div>
          </div>

          <Badge color="green" name="24*7 Support" />
          <div className="flex items-start space-x-4">
            <div>
              <h3 className="text-xl font-bold text-[#E2E8F0]">
                Exceptional Customer Service
              </h3>
              <p className="text-[#94A3B8] max-w-[80%]">
                We pride ourselves on providing a hassle-free rental experience,
                ensuring you hit the road quickly and confidently.
              </p>
            </div>
          </div>

          {/* Feature 3: Affordable Pricing */}
          <Badge color="red" name="Secure"/>
          <div className="flex items-start space-x-4">
           
            <div>
              <h3 className="text-xl font-bold text-[#E2E8F0]">Affordable Pricing</h3>
              <p className="text-[#94A3B8] max-w-[80%]">
                We offer transparent pricing with no hidden fees. Check out our
                special offers and discounts to get the best deal on your next
                rental.
              </p>
            </div>
          </div>
        </div>

        {/* Right Column: Image Section */}
        <div className="relative">
          <img
            src={why305}
            alt="Luxury Car"
            className="rounded-xl object-cover"
          />
        </div>
      </div>
    </div>
  );
};

export default WhyLevel305;
