import HubSpotBot from "components/Chatbot/Chatbot";
import React from "react";
import MyRouter from "routers/index";

function App() {
  return (
    <div className="bg-white text-base dark:bg-[#262626] text-neutral-900 dark:text-neutral-200">
      {/* <HubSpotBot/> */}
      <MyRouter />
    </div>
  );
}

export default App;
