import { AxiosError } from "axios";
import customAxios from "config";

export const getRentalAgreementContent = async (
  path: string
): Promise<{ data?: any; error?: string }> => {
  try {
    const response = await customAxios.get(
      `/api/company/get-booking-pdf-data/${path}/`
    );
    return response.data; // Return response data if successful
  } catch (error) {
    if (error instanceof AxiosError && error.response) {
      return {
        error:
          error.response.data.detail ||
          "An error occurred during fetching agreement",
      };
    }
    return { error: "An unknown error occurred." };
  }
};

export async function sendRentalAgreement(salt_key: any, type: string) {
  try {
    const response = await customAxios.get(
      `/api/company/resend-email/${salt_key}/${type}/`
    );
    return response.data; // Return response data if successful
  } catch (error) {
    if (error instanceof AxiosError && error.response) {
      return {
        error:
          error.response.data.detail ||
          "An error occurred during sending the agreement",
      };
    }
    return { error: "An unknown error occurred." };
  }
}
