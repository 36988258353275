import { getBonzahPayload } from "api/types";
import { AxiosError } from "axios";
import customAxios from "config";

export const getBonzahProducts = async (
  payload: getBonzahPayload
): Promise<{ data?: any; error?: string }> => {
  try {
    const response = await customAxios.post<any>(
      "/api/bonza/get-quote/",
      payload
    );
    return response;
  } catch (error) {
    if (error instanceof AxiosError) {
      return {
        error:
          error.response?.data?.detail ||
          "An error occurred during fetching Bonzah Poducts.",
      };
    }
    return { error: "An unknown error occurred." };
  }
};
