export const changeDate = (
  dates: [Date | null, Date | null],
  startHours: string,
  startMinutes: string,
  startPeriod: string,
  endHours: string,
  endMinutes: string,
  endPeriod: string,
  setStartDate: React.Dispatch<React.SetStateAction<Date>>,
  setEndDate: React.Dispatch<React.SetStateAction<Date>>
) => {
  const [start, end] = dates;

  const newStart = new Date(start!);
  // Preserve time components
  if (start) {
    newStart.setHours(
      startPeriod == "PM" ? parseInt(startHours) + 12 : parseInt(startHours)
    );
    newStart.setMinutes(parseInt(startMinutes));
  }
  const newEnd = new Date(end ? end! : start!);

  // Preserve time components
  if (end) {
    newEnd.setHours(
      endPeriod == "PM" ? parseInt(endHours) + 12 : parseInt(endHours)
    );
    newEnd.setMinutes(parseInt(endMinutes));
  }

  setStartDate(newStart);
  setEndDate(end ? newEnd : end!);
};
