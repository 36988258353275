import React, { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Page } from "./types";
import ScrollToTop from "./ScrollToTop";
import Footer from "shared/Footer/Footer";
import Page404 from "containers/Page404/Page404";
import ListingCarPage from "containers/ListingCarPage/ListingCarPage";
import CheckOutPage from "containers/CheckOutPage/CheckOutPage";
import PayPage from "containers/PayPage/PayPage";
import AccountPage from "containers/AccountPage/AccountPage";
import AccountPass from "containers/AccountPage/AccountPass";
import AccountSavelists from "containers/AccountPage/AccountSavelists";
import AccountBilling from "containers/AccountPage/AccountBilling";
import PageContact from "containers/PageContact/PageContact";
import PageAbout from "containers/PageAbout/PageAbout";
import PageSignUp from "containers/PageSignUp/PageSignUp";
import PageLogin from "containers/PageLogin/PageLogin";
import PageForgotPass from "containers/PageForgotPass/PageForgotPass";
import SiteHeader from "containers/SiteHeader";
import FooterNav from "components/FooterNav";
import useWindowSize from "hooks/useWindowResize";
import PageHome3 from "containers/PageHome/PageHome3";
import ListingCarDetailPage from "containers/ListingDetailPage/listing-car-detail/ListingCarDetailPage";
import AvailableCarsPage from "containers/AvailableCarsPage/AvailableCarsPage";
import ComingSoon from "components/ComingSoon";
import {
  Transition,
  Dialog,
  TransitionChild,
  DialogPanel,
} from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/solid";
import { useData } from "data/data-provider";
import PageResetPass from "containers/PageResetPass/PageResetPass";
import PageSetPass from "containers/PageSetPass/PageSetPass";
import BookingDetailsPage from "components/BookingDetailsPage/BookingDetailsPage";

export const pages: Page[] = [
  { path: "/", exact: true, component: PageHome3 },
  { path: "/home-3", component: PageHome3 },

  //
  { path: "/available-car", component: AvailableCarsPage },
  { path: "/listing-car", component: ListingCarPage },
  { path: "/listing-car-detail", component: ListingCarDetailPage },

  //
  { path: "/checkout", component: CheckOutPage },
  { path: "/pay-done", component: PayPage },
  { path: "/booking-details/:bookingId", component: BookingDetailsPage },
  //
  { path: "/account", component: AccountPage },
  { path: "/account-password", component: AccountPass },
  { path: "/account-bookings", component: AccountSavelists },
  { path: "/account-billing", component: AccountBilling },

  //
  { path: "/resetpassword/:token", component: PageResetPass },
  { path: "/setpassword/:token", component: PageSetPass },
  { path: "/contact", component: PageContact },
  { path: "/about", component: PageAbout },
  { path: "/coming-soon", component: ComingSoon },
  //
];

export enum AuthPages {
  Login,
  Signup,
  ForgotPass,
}
const MyRoutes = () => {
  let { isAuthPanelOpen, setIsAuthPanelOpen } = useData();
  let [authPageName, setAuthPageName] = useState<AuthPages>(AuthPages.Login);
  let WIN_WIDTH = useWindowSize().width;
  if (typeof window !== "undefined") {
    WIN_WIDTH = WIN_WIDTH || window.innerWidth;
  }

  useEffect(() => {
    if (isAuthPanelOpen) {
      setAuthPageName(AuthPages.Login);
    }
  }, [isAuthPanelOpen]);

  return (
    <BrowserRouter>
      <ScrollToTop />
      <SiteHeader />

      <Routes>
        {pages.map(({ component, path }) => {
          const Component = component;
          return <Route key={path} element={<Component />} path={path} />;
        })}
        <Route element={<Page404 />} />
      </Routes>

      {WIN_WIDTH < 768 &&  <FooterNav setIsAuthPanelOpen={setIsAuthPanelOpen} />}
      <Footer />
      <Transition appear show={isAuthPanelOpen}>
        <Dialog
          as="div"
          className="relative z-20 focus:outline-none"
          onClose={() => {
            setIsAuthPanelOpen(false);
          }}
        >
          <div className="fixed inset-0 z-1000 w-screen overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4">
              <TransitionChild
                enter="ease-out duration-300"
                enterFrom="opacity-0 transform-[scale(95%)]"
                enterTo="opacity-100 transform-[scale(100%)]"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 transform-[scale(100%)]"
                leaveTo="opacity-0 transform-[scale(95%)]"
              >
                <DialogPanel className="w-full max-w-md lg:h-[700px] lg:max-w-2xl bg-[#262626]  rounded-xl p-6  border border-text-color shadow-2xl">
                  <div className="flex justify-end">
                    <button
                      onClick={() => {
                        setIsAuthPanelOpen(false);
                      }}
                      className="text-text-color"
                    >
                      <XMarkIcon className="h-6 w-6" />
                    </button>
                  </div>
                  {authPageName === AuthPages.Login ? (
                    <PageLogin setAuthPageName={setAuthPageName} />
                  ) : authPageName === AuthPages.ForgotPass ? (
                    <PageForgotPass setAuthPageName={setAuthPageName} />
                  ) : (
                    <PageSignUp setAuthPageName={setAuthPageName} />
                  )}
                </DialogPanel>
              </TransitionChild>
            </div>
          </div>
        </Dialog>
      </Transition>
    </BrowserRouter>
  );
};

export default MyRoutes;