import rightImg from "images/image.png";
import readytodrive from "images/readytodrive.png";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import SectionGridFeaturePlaces from "./SectionGridFeaturePlaces";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import SectionHero3 from "components/SectionHero/SectionHero3";
import SectionHero from "containers/PageAbout/SectionHero";
import SectionClientSay from "components/SectionClientSay/SectionClientSay";
import { globalJson } from "global/global_json";
import HomeAboutSection from "containers/PageAbout/HomeAboutSection";
import GoPlacesWith from "components/GoPlacesWith/GoPlacesWith";
import RentalPolicies from "components/RentalPolicies/RentalPolicies";
import ReadytoDrive from "containers/PageAbout/ReadytoDrive";
import SectionFounder from "containers/PageAbout/SectionFounder";
import SectionStatistic from "containers/PageAbout/SectionStatistic";
import WhyLevel305 from "containers/why305/why305";

function PageHome3() {
  return (
    <div className="nc-PageHome3 relative  bg-[#262626]">
      {/* GLASSMOPHIN */}
      <BgGlassmorphism />
      {/* <DownloadLink /> */}

      {/* SECTION HERO */}
      <div>
        <SectionHero3 />
      </div>
      <div className="container relative py-16">
        <SectionGridFeaturePlaces />
      </div>
      <div className="py-16 px-12 bg-[#CEBEED]">
        <HomeAboutSection
          rightImg={rightImg}
          heading="Welcome to Infinney!"
          btnText=""
          subHeading="A travel concierge service here in New Jersey and New York, We specialize in car rentals, house rentals, boat rentals, club/dinner reservations and more! We pride ourselves in excellent customer service. With a dedicated team behind you every step of the way, we ensure a worry and hassle-free experience from start to finish!"
        />
      </div>

      <div className="py-16">
        <WhyLevel305 />
      </div>
      <div className="relative py-16 bg-[#00000033]">
        {/* <BackgroundSection /> */}
        <SectionClientSay uniqueClassName="PageAbout_" />
      </div>
      <div className="pt-24">
     
        <div className="p-16 bg-[#CEBEED] ">
          {/* <BackgroundSection /> */}
          <ReadytoDrive
            rightImg={readytodrive}
            heading="Book your ride today!"
            btnText=""
            subHeading="Ready to hit the road? Booking with Driven Enterprises is fast and simple. Browse our fleet online, choose your vehicle, and reserve it within minutes. For personalized assistance, feel free to contact our team directly."
          />

          {/* <SectionSubscribe2 /> */}
        </div>
      </div>
    </div>
  );
}

export default PageHome3;
