import rightImg from "images/aboutus.png";
import React, { FC } from "react";
import SectionFounder from "./SectionFounder";

import { Helmet } from "react-helmet";

import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import SectionHero from "./SectionHero";
import SectionClientSay from "components/SectionClientSay/SectionClientSay";
import { globalJson } from "global/global_json";

import Features from "components/Feature/Feature";
import PageContact from "containers/PageContact/PageContact";
import SectionStatistic from "./SectionStatistic";

export interface PageAboutProps {
  className?: string;
}

const PageAbout: FC<PageAboutProps> = ({ className = "" }) => {
  return (
    <div
      className={`nc-PageAbout overflow-hidden relative bg-[#262626] ${className}`}
      data-nc-id="PageAbout"
    >
      <Helmet>
        <title>About || Car rental</title>
      </Helmet>

      {/* ======== BG GLASS ======== */}
      <BgGlassmorphism />

      <div className="container py-12 ">
        <SectionHero
          rightImg={rightImg}
          heading="Welcome to InFinney"
          btnText=""
          subHeading={globalJson?.about_us_para}
        />
      </div>
      <div className="px-12">
        {/* <div >
          <SectionFounder />
        </div> */}
        {/* <div >
          <SectionStatistic />
        </div> */}
      </div>
      <div className="py-16 ">
        <Features />
      </div>
      {/* <SectionFounder /> */}
      <div className="relative py-16 bg-[#00000033] ">
        {/* <BackgroundSection /> */}
        <SectionClientSay uniqueClassName="PageAbout_" />
      </div>
      <PageContact />
    </div>
  );
};

export default PageAbout;
